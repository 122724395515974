export const routes = [
    {
        path: "/",
        name: "home",
        component: () => import('../views/CardView'),
    },
    {
        path: "/personal-data",
        name: "personal-data",
        component: () => import('../views/PersonalDataView'),
    },
    {
        path: "/confirmation",
        name: "confirmation",
        component: () => import('../views/ConfirmationView'),
    },
]
