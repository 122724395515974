import Vue from "vue";
import Vuex from 'vuex';
Vue.use(Vuex);

import popup from './states/popup';


const index = new Vuex.Store({
    modules: {
        popup: popup,
    }
});

export default index;
