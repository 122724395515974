<template>
    <div class="footer">
        <div class="footer__top-line"></div>
        <h3 class="f16 fw700">Masz pytania dotyczące aktywacji karty mBank?</h3>
        <p class="f16 fw300">Skontaktuj się z nami pod numerem <b>801 802 629*</b>.<br/>Infolinia czynna w dni robocze od pon. do pt. w godz. 9:00 – 16:00</p>
        <p class="f16 fw300">* Opłata za połączenie zgodna z cennikiem operatora.</p>
    </div>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style lang="scss" scoped>
    .footer {
        padding: 2rem 0;
        position: relative;
        text-align: center;

        &__top-line {
            width: 56px;
            height: 1px;
            background-color: #CCC;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>
