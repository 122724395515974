<template>

   <div :class="['popup', type, visible ? 'active' : '']">
       <div class="popup__bg" @click="close"/>
       <div :class="['popup__content', size]">
           <div class="popup__close" @click="close"/>
           <p v-if="title !== ''" class="popup__title">{{ title }}</p>
           <div v-dompurify-html="body" class="popup__text"/>
       </div>
   </div>

</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "PopupComponent",
        computed: mapState({
            visible: state => state.popup.visible,
            size: state => state.popup.payload.size,
            type: state => state.popup.payload.type,
            title: state => state.popup.payload.title,
            body: state => state.popup.payload.body,
        }),
        methods: {
            close() {
                this.$store.commit('hidePopup');
            }
        }
    }
</script>

<style lang="scss">

.popup{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-out;
    &__bg{
        width: 100%;
        height: 100%;
        background: rgba(18,47,52, .7);
        position: absolute;
        top: 0;
        left: 0;
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAGFBMVEUAAAD///////////////////////////8jfp1fAAAAB3RSTlMA2hq+JRu9E1zjOwAAAEhJREFUCNdjcFNgAAKmFAZzIRBDsZhBsVABKCAuBMYgHpgAccDCYAGQEEgALAQUgDPgUgjFcO0IA8HCrEAexNLAYgZnsFZWEwCpGg0INV0s1QAAAABJRU5ErkJggg=='), auto;
    }
    &__close{
        width: 26px;
        height: 26px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAGFBMVEUAAAAbGxsbGxsbGxsbGxsbGxsbGxsbGxuKKAZmAAAAB3RSTlMA2hq+JRu9E1zjOwAAAEhJREFUCNdjcFNgAAKmFAZzIRBDsZhBsVABKCAuBMYgHpgAccDCYAGQEEgALAQUgDPgUgjFcO0IA8HCrEAexNLAYgZnsFZWEwCpGg0INV0s1QAAAABJRU5ErkJggg==') center no-repeat;
        position: absolute;
        top: 9px;
        right: 12px;
        cursor: pointer;
    }
    &__title{
        font-size: 20px;
        font-weight: 700;
    }
    &.ok{
        .popup__bg{
            opacity: .8;
            background: rgb(18,47,52);
            background: linear-gradient(130deg, rgba(39,185,168,0.88) 0%, rgba(18,47,52,0.88) 100%);
        }
    }
    &.err{
        color: #000;
        .popup__bg{
            background: rgba(183, 0, 0, .7);
        }
        .popup__title{
            color: #78000d;
        }
    }
    &__content{
        width: 100%;
        max-width: 500px;
        padding: 40px 30px;
        background: #fff;
        border-radius: 0;
        overflow: hidden;
        font-size: 16px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.sm{
            max-width: 500px;
        }
        &.lg{
            max-width: 700px;
        }
        &.xl{
            max-width: 970px;
        }
        @media (max-width: 550px) {
            height: 100%;
            padding: 40px 10px;
            font-size: 12px;
            border-radius: 0;
        }
    }
    &.active{
        visibility: visible;
        opacity: 1;
    }
}

</style>
