<template>
    <div v-if="show" class="cookie-warn" ref="cookie-warn">
        <div class="cookie-warn__header"><b>CIASTECZKA</b></div>
        <p>Używamy plików cookies (tzw. „ciasteczka”) w&nbsp;celu optymalizacji korzystania ze stron internetowych, tworzenia statystyk. Możesz wyłączyć ten mechanizm w&nbsp;dowolnym momencie w&nbsp;ustawieniach przeglądarki.</p>
        <div class="text-center">
            <span id="close-cookie-warn" @click="closeCookieWarn">Zamknij</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookiesComponent",
    data(){
        return {
            show: false,
            name: 'aktywacja-kart-albedo',
        }
    },
    mounted(){
        this.initCookies();
    },
    methods: {
        initCookies(){
            if (!localStorage.getItem(this.name)) {
                this.show = true;
            }
        },
        closeCookieWarn(){
            localStorage.setItem(this.name, 'true');
            this.show = false;
        }
    },
}
</script>



<style lang="scss" scoped>
$cookie_accent: #BF0303;

.cookie-warn {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background: #fff;
    color: #000;
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 99;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
    border-top: 2px solid $cookie_accent;
    transition: .4s ease-out;
    @media (max-width: 700px) {
        width: 100%;
        max-width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
    }
    p {
        max-width: 1200px;
        line-height: 18px;
        font-size: 12px;
        margin: 0 auto 20px;
    }
    a{
        color: #fff;
        font-weight: 700;
        text-decoration: underline;
        &:hover{
            color: #ffa540;
        }
    }
}
#close-cookie-warn{
    padding: 7px 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 5px;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;
    color: #fff;
    background-color: #BF0303;
    cursor: pointer;
}

</style>
